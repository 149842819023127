import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
  persist(
    set => ({
      payload: null,
      isOpen: false,
      reportpayload: null,
      finalizeReport: null,
      imageOverlayProps: null,
      reportData: null,
      isKeyImage: false,

      // showHeader: "",

      setPayload: newpayload => set({ payload: newpayload }),
      setIsOpen: newisOpen => set({ isOpen: newisOpen }),
      setReportPayload: newReppayload => set({ reportpayload: newReppayload }),
      setFinalizeReport: newFinalizeReport => set({ finalizeReport: newFinalizeReport }),
      setImageOverlayProps: newImageOverlayProps => set({ imageOverlayProps: newImageOverlayProps }),
      setReportData: (data) => set({ reportData: data }),
      setIsKeyImage: newisKeyImage => set({ isKeyImage: newisKeyImage }),
      // setShowHeader: newshowHeader => set({ showHeader: newshowHeader }),
    }),
    {
      name: 'my-shared-store',
      partialize: state => ({ reportpayload: state.reportpayload }),
    }
  )
);

export default useStore;
