import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import './ViewportOverlay.css';
// import getActiveViewportEnabledElement from '../../../../../extensions/cornerstone/src/utils/getActiveViewportEnabledElement';
// import { ToolGroupManager } from '@cornerstonejs/tools';
// import { getEnabledElement, getEnabledElement as OHIFgetEnabledElement } from '../../../../../extensions/cornerstone/src/state';
import useStore from '../../../uiState';
import config from '../../../../../config';

const baseURL = config.API_BASE_URL;

// The overlay-top and overlay-bottom classes are explicitly needed to offset
// the overlays (i.e. via absolute positioning) such the ViewportActionCorners
// have space for its child components.
// ToDo: offset the ViewportOverlay automatically via css to account for the
// space needed for ViewportActionCorners.
const classes = {
  topLeft: 'overlay-top left-viewport',
  topRight: 'overlay-top right-viewport-scrollbar',
  bottomRight: 'overlay-bottom right-viewport-scrollbar',
  bottomLeft: 'overlay-bottom left-viewport',
};

export type ViewportOverlayProps = {
  topLeft: React.ReactNode;
  topRight: React.ReactNode;
  bottomRight: React.ReactNode;
  bottomLeft: React.ReactNode;
  color?: string;
};

const ViewportOverlay = ({
  topLeft,
  topRight,
  bottomRight,
  bottomLeft,
  color = 'text-primary-light',
}: ViewportOverlayProps) => {
  const overlay = 'absolute pointer-events-none viewport-overlay';
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const studyInstanceUID = params.get('StudyInstanceUIDs');
  // const [reportData, setReportData] = useState<any>();
  // const imageOverLay = useStore(state => state.imageOverlayProps);
  const { reportData, setReportData, imageOverlayProps: imageOverLay } = useStore();
  const fetchInitiated = useRef(false);

  useEffect(() => {
  }, [imageOverLay]);

  useEffect(() => {
    // Only fetch if reportData is not already cached and fetch has not been initiated
    if (!reportData && !fetchInitiated.current) {
      fetchTemplateData();
      fetchInitiated.current = true; // Mark fetch as initiated
    }
  }, []);

  const fetchTemplateData = async () => {
    const payload = {
      "form": null,
      "condition": {
        "studyId": studyInstanceUID,
        // "value": 0
      }
    };

    try {
      const response = await fetch(`${baseURL}/Report/GetReportTemp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const repdata = data.reportData[0];
        // setReportData(repdata);
        setReportData(repdata);// Store in global state to prevent re-fetching
      } else {
        throw new Error('Failed to save content');
      }
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  return (
    <div
      className={classnames(
        color ? color : 'text-aqua-pale',
        'text-[13px]',
        'leading-5',
        'overlay-text'
      )}
    >
      <div
        data-cy={'viewport-overlay-top-left'}
        className={classnames(overlay, classes.topLeft)}
      >

        {imageOverLay && (topLeft)}

        {imageOverLay && reportData && (
          <>
            <h1 className="text-blue-300">{reportData.patientId}</h1>
            <h1 className="text-blue-300">{reportData.name}</h1>
            <h1 className="text-blue-300">{reportData.age}</h1>
            <h1 className="text-blue-300">{reportData.gender}</h1>
          </>
        )}
      </div>
      <div
        data-cy={'viewport-overlay-top-right'}
        className={classnames(overlay, classes.topRight)}
        style={{ transform: 'translateX(-8px)' }} // shift right side overlays by 4px for better alignment with ViewportActionCorners' icons
      >
        {topRight}
      </div>
      <div
        data-cy={'viewport-overlay-bottom-right'}
        className={classnames(overlay, classes.bottomRight)}
        style={{ transform: 'translateX(-8px)' }} // shift right side overlays by 4px for better alignment with ViewportActionCorners' icons
      >
        {bottomRight}
      </div>
      <div
        data-cy={'viewport-overlay-bottom-left'}
        className={classnames(overlay, classes.bottomLeft)}
      >
        {bottomLeft}
      </div>
    </div>
  );
};

export default ViewportOverlay;
