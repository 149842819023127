import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import JoditEditor, { IJoditEditorProps } from 'jodit-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import useStore from '../../../../uiState';
import config from '../../../../../../config';

const baseURL = config.API_BASE_URL;

function CRReportForm({
  onSave,
  studyInstanceUID,
  placeholder,
  reportData,
  selectedReport,
  rightPanelClosedState,
  setRightPanelClosed,
  onClose,
  onFinzaliedSave,
  onRemove,
  reportType,
  doctorData,
}) {
  const [showForm, setShowForm] = useState(true);
  const [content, setContent] = useState('Start typing...');
  const editor = useRef(null);
  const [clientID, setClientID] = useState();
  const [appUserID, setAppUserID] = useState();
  const [templateID, setTemplateID] = useState();
  const [patientName, setPatientName] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const isFinalized = reportType == 'Finalized';
  const finalizeReport = useStore(state => state.finalizeReport);
  const [imagePreview, setImagePreview] = useState('');
  const setFinalizeReport = useStore(state => state.setFinalizeReport);

  const config: IJoditEditorProps['config'] = useMemo(() => ({
    readonly: isFinalized,
    placeholder: 'Start typing...',
    toolbarSticky: false,
    buttons: [
      'bold', 'italic', 'underline', 'strikethrough', 'ul', 'ol', 'eraser', 'indent', 'outdent',
      'align', 'undo', 'redo', 'font', 'fontsize', 'cut', 'copy', 'paste', 'source', 'dots'
    ],
    removeButtons: [
      'superscript', 'subscript', 'image', 'file', 'video', 'selectall', 'hr', 'table', 'link', 'symbols', 'ai-commands',
      'ai-assistant', 'find', 'fullsize', 'preview', 'about', 'speechRecognize', 'brush',
    ],
    enter: 'p' as 'p',
    // style: {
    //   fontFamily: 'Times New Roman, Times, serif',
    // },
  }), [isFinalized]);

  useEffect(() => {
    if (editor.current) {
      setTimeout(() => {
        editor.current.focus(false);
      }, 100);
    }
  }, []);

  // const config = useMemo(
  //   () => ({
  //     readonly: isFinalized,
  //     placeholder: 'Start typing...',
  //     toolbarSticky: false,
  //     buttons: [
  //       'bold',
  //       'italic',
  //       'underline',
  //       'strikethrough',
  //       'eraser',
  //       'ul',
  //       'ol',
  //       'indent',
  //       'outdent',
  //       'align',
  //       'undo',
  //       'redo',
  //       'font',
  //       'fontsize',
  //       'brush',
  //       'cut',
  //       'copy',
  //       'paste',
  //       'source',
  //       'dots', //show more
  //     ],
  //     removeButtons: [
  //       'superscript',
  //       'subscript',
  //       'image',
  //       'file',
  //       'video',
  //       'selectall',
  //       'hr',
  //       'table',
  //       'link',
  //       'symbols',
  //       'ai-commands',
  //       'ai-assistant',
  //       'find',
  //       'fullsize',
  //       'preview',
  //       'print',
  //       'about',
  //       'speechRecognize',
  //       'brush',
  //     ],
  //     enter: 'P',
  //   }),
  //   [isFinalized, placeholder]
  // );

  useEffect(() => { }, [reportType, finalizeReport, selectedReport]);

  useEffect(() => {
    const repdata = reportData[0];
    setClientID(repdata.clientId);
    setAppUserID(repdata.appUserId);
    setPatientName(repdata.name);

    const reportTemplate = selectedReport?.report;
    setTemplateID(selectedReport?.id);

    let tableContent;
    if (!selectedReport?.reportId) {
      tableContent = `
        <p style="font-size: 16px; line-height: 1.5; margin-bottom: 10px;">${reportTemplate}</p>
      `;
    } else {
      tableContent = reportTemplate;
    }
    setContent(tableContent);
  }, [selectedReport, reportData]);

  const handleChange = (newContent) => {
    setContent(newContent.trimStart());
  };


  const handleSave = async () => {
    const iswindowOpen: boolean = false;
    await onSave(patientName, content, appUserID, clientID, templateID, iswindowOpen);
    setRightPanelClosed(true);
    onClose();
  };

  // const config = useMemo(
  //   () => ({
  //     readonly: isFinalized,
  //     placeholder: placeholder || 'Start typing...',
  //   }),
  //   [placeholder]
  // );

  const handleSaveAndFinalize = async () => {
    const currentTime = new Date().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    const safeData = (data) => (data != null && data != undefined ? data : '');
    const formatDate = dateString => {
      if (!dateString) {
        return '';
      }
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const currentDate = formatDate(new Date());

    const repdata = reportData[0];

    const tableContent = `
    <table class="table text-lg no-edit" style="width: 100%; border-collapse: collapse; border: 1px solid #000000;" contenteditable="false">
     <tbody>
       <tr>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Name</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${safeData(repdata.name)}</td>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Patient ID</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${safeData(repdata.patientId)}</td>
       </tr>
       <tr>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Accession No</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${safeData(repdata.accession)}</td>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Date</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${currentDate}</td>
       </tr>
       <tr>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Age/Gender</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${safeData(repdata.age)} / ${safeData(repdata.gender)}</td>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Center Name</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${safeData(repdata.center)}</td>
       </tr>
       <tr>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Study Date</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${formatDate(repdata.studyDate)}</td>
         <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Modality</th>
         <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${safeData(repdata.modality)}</td>
       </tr>
     </tbody>
   </table>
   `;

    let imageUrl = '';
    let docSign;

    const docData = doctorData[0];
    if (docData) {
      docSign = docData.drSignature;
    }

    if (docSign) {
      try {
        const imgFiles = JSON.parse(docSign);
        const imageFilePath = imgFiles[0].filePath;
        const correctedFilePath = imageFilePath.replace(/\\/g, '/');
        imageUrl = `${baseURL}/ImportFiles/${correctedFilePath}`;
        setImagePreview(imageUrl);
      } catch (error) {
        console.error('Failed to parse image URL:', error);
      }
    }

    const signatureDetails = `
    <div style="margin-top: 20px; page-break-inside: avoid;">
      <div style="text-align: right; margin-top: 20px; page-break-inside: avoid;font-size: 16px;">
        ${docData && imageUrl
        ? `<img src="${imageUrl}" alt="Doctor's Signature" crossOrigin="anonymous"
              style="display: inline-block; width: 164px; height: auto; max-height: 150px; margin-bottom: 5px;" />`
        : ''
      }
          <div style="text-align: right; line-height: 1.5; font-size: 18px; font-weight: bold;">
            ${docData && safeData(docData.name)
        ? `<span style="text-align: right; margin-top: 5px;">${safeData(docData.name)}</span><br/>`
        : ''
      }
            ${docData && safeData(docData.doctorDegree)
        ? `<span style="text-align: right; margin-top: 5px;">${safeData(docData.doctorDegree)}</span><br/>`
        : ''
      }
            ${docData && safeData(docData.registrationNo)
        ? `<span style="text-align: right; margin-top: 5px;">Reg - ${safeData(docData.registrationNo)}</span>`
        : ''
      }
      </div>
      <div style="text-align: left; margin-top: 10px;">
        <div class="container mt-4">
          <table style="width: 100%; font-family: Arial, sans-serif; font-size: 16px; border-spacing: 0; border-collapse: collapse;border:none">
            <tr>
              <td style="font-weight: bold; vertical-align: top; padding-right: 4px; white-space: nowrap;border:none">NOTE:</td>
              <td style="text-align: justify;border:none; font-size: 16px;">
                This report has been prepared based on the shared details. If you have any inquiries or need further explanations,
                please contact your physician.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div style="clear: both;"></div>
    `;

    if (!content) {
      console.error("Content is empty");
      return;
    }

    const updatedContent = `${tableContent}<br/>${content}<br/>${signatureDetails}`;
    setContent(updatedContent);

    // const updatedContent = content.replace(regexPattern, `$1${currentTime}$3`) + signatureDetails;
    // setContent(updatedContent);

    const iswindowOpen = false;
    await onSave(patientName, updatedContent, appUserID, clientID, templateID, iswindowOpen);
    onFinzaliedSave();
    setFinalizeReport(isFinalized);
    setRightPanelClosed(true);
    onClose();
  };

  // <tr>
  //   <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Reported Time</th>
  //   <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;" id="reportedTime">${currentTime}</td>
  //   <th style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 22px; font-family: 'Times New Roman', Times, serif;">Reported by</th>
  //   <td style="text-align: left; border: 1px solid #000000; padding: 8px;font-size: 20px;">${safeData(repdata.assignedTo)}</td>
  // </tr>

  const openReportInNewTb = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const iswindowOpen: boolean = true;
    onSave(patientName, content, appUserID, clientID, templateID, iswindowOpen);
    setRightPanelClosed(true);
    localStorage.setItem('refreshNeeded', 'true');
  };

  const handleRemoveReport = async () => {
    onRemove(patientName, content, appUserID, clientID, templateID);
    setRightPanelClosed(true);
    onClose();
  };

  const handleCloseReport = async () => {
    setIsDialogOpen(false);
    setRightPanelClosed(true);
    onClose();
  };

  const deletefooterContent = (
    <div className=" my-2 mx-2">
      <Button
        label="No"
        className="bg-customblue-30 text p-button-text mx-4 px-3 py-2 text-white"
        icon="pi pi-times"
        onClick={handleCloseReport}
      />
      <Button
        label="Yes"
        className="text bg-green-600 px-3 py-2 text-white"
        icon="pi pi-check"
        onClick={handleRemoveReport}
        autoFocus
      />
    </div>
  );

  return (
    showForm && (
      <>
        <div className=" reportEditor">
          <div
            className="rounded border"
            style={{ width: '100%', height: '100%', resize: 'vertical' }}
          >
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              onBlur={handleChange}
              className="myjoditEditor"
            />

            <div className="p-grid p-justify-between">
              <div className="editorBtnContainer mt-2 flex flex-row">
                <button
                  type="submit"
                  onClick={handleSave}
                  className={`mx-2 rounded p-2 text-lg font-normal ${isFinalized
                    ? 'cursor-not-allowed bg-gray-400 font-bold text-black'
                    : 'bg-green-600 text-white'
                    }`}
                  disabled={isFinalized}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleSaveAndFinalize}
                  className={`mr-2 rounded p-2 text-lg font-normal ${isFinalized
                    ? 'cursor-not-allowed bg-gray-400 font-bold text-black'
                    : 'bg-green-700 text-white'
                    }`}
                  disabled={isFinalized}
                >
                  Save & Finalize
                </button>

                <button
                  type="button"
                  onClick={() => setIsDialogOpen(true)}
                  className="mr-2 rounded bg-red-400 p-2 text-lg font-normal text-white"
                >
                  Delete
                </button>

                <button
                  type="button"
                  onClick={onClose}
                  className=" mr-2 rounded bg-red-400 p-2 text-lg font-normal text-white"
                >
                  Close
                </button>
              </div>

              {!isFinalized && (
                <div className="mt-3 mb-3 ml-5 flex flex-row flex-1">
                  <a
                    href={
                      window.location.origin +
                      `/reportviewer?${new URLSearchParams({
                        StudyInstanceUIDs: studyInstanceUID,
                      }).toString()}`
                    }
                    className="mr-2 rounded text-lg font-bold text-blue-600 underline"
                    target="_blank"
                    onClick={openReportInNewTb}
                    rel="noreferrer"
                  >
                    Open in New Tab
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* image delete confirmation  */}
        <Dialog
          header="Confirmation"
          visible={isDialogOpen}
          style={{
            width: '30vw',
            backgroundColor: '#000712',
            color: 'white',
            margin: '10px',
            border: '1px solid #ffffff',
          }}
          onHide={() => setIsDialogOpen(false)}
          footer={deletefooterContent}
        >
          <p className="m-4">Are you sure you want to Delete Report ??</p>
        </Dialog>
      </>
    )
  );
}

CRReportForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  studyInstanceUID: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  reportData: PropTypes.array.isRequired,
  selectedReport: PropTypes.object,
  rightPanelClosedState: PropTypes.bool,
  setRightPanelClosed: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onFinzaliedSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
};

export default CRReportForm;
